
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93JGJOv3QYRPMeta } from "/mcc/src/pages/[...slug].vue?macro=true";
import { default as emailsOK8grCxJlVMeta } from "/mcc/src/pages/admin/emails.vue?macro=true";
import { default as usersJFAVv9uWKgMeta } from "/mcc/src/pages/admin/users.vue?macro=true";
import { default as beginner_45course_45datesX7uuTA5baKMeta } from "/mcc/src/pages/beginner-course-dates.vue?macro=true";
import { default as calendareEDh3jWgEBMeta } from "/mcc/src/pages/calendar.vue?macro=true";
import { default as coached_45paddlesmpqlAFxWQgMeta } from "/mcc/src/pages/coached-paddles.vue?macro=true";
import { default as confirm_45emailQQOIhywwelMeta } from "/mcc/src/pages/confirm-email.vue?macro=true";
import { default as contact_45uspVuMl85qXJMeta } from "/mcc/src/pages/contact-us.vue?macro=true";
import { default as demo_45eventCygYYHa2DKMeta } from "/mcc/src/pages/demo-event.vue?macro=true";
import { default as demolk4jSFjkanMeta } from "/mcc/src/pages/demo.vue?macro=true";
import { default as editbYbI1UQG3EMeta } from "/mcc/src/pages/events/[id]/[...slug]/edit.vue?macro=true";
import { default as indexjD2afo2eZlMeta } from "/mcc/src/pages/events/[id]/[...slug]/index.vue?macro=true";
import { default as consentYePiv2EW1uMeta } from "/mcc/src/pages/events/consent.vue?macro=true";
import { default as newmzBmU05D4nMeta } from "/mcc/src/pages/events/new.vue?macro=true";
import { default as faqsF7IMOSIVhFMeta } from "/mcc/src/pages/faqs.vue?macro=true";
import { default as forgot_45passwordfJ9MZaopxqMeta } from "/mcc/src/pages/forgot-password.vue?macro=true";
import { default as index519BfSTGh2Meta } from "/mcc/src/pages/index.vue?macro=true";
import { default as acceptP68kX8xKtrMeta } from "/mcc/src/pages/invites/accept.vue?macro=true";
import { default as createpOWqsBgavRMeta } from "/mcc/src/pages/invites/create.vue?macro=true";
import { default as sendruwuZscaUFMeta } from "/mcc/src/pages/invites/send.vue?macro=true";
import { default as loginNWjMCkw1ecMeta } from "/mcc/src/pages/login.vue?macro=true";
import { default as member_45checkbpWLRV4KwFMeta } from "/mcc/src/pages/member-check.vue?macro=true";
import { default as index8s3tp2FFTwMeta } from "/mcc/src/pages/minutes/index.vue?macro=true";
import { default as new312OD9hOsLMeta } from "/mcc/src/pages/minutes/new.vue?macro=true";
import { default as _91_46_46_46slug_93nW9ocLL7Y9Meta } from "/mcc/src/pages/news/[id]/[...slug].vue?macro=true";
import { default as editrsrDuQTcV5Meta } from "/mcc/src/pages/news/[id]/edit.vue?macro=true";
import { default as index3hVbyF2xwFMeta } from "/mcc/src/pages/news/index.vue?macro=true";
import { default as newnBRCl8JMgJMeta } from "/mcc/src/pages/news/new.vue?macro=true";
import { default as unsubscribeFBgkA9WBUMMeta } from "/mcc/src/pages/news/unsubscribe.vue?macro=true";
import { default as indexwwMpXeGt7dMeta } from "/mcc/src/pages/newsletters/index.vue?macro=true";
import { default as newWhpEQlrKuOMeta } from "/mcc/src/pages/newsletters/new.vue?macro=true";
import { default as old_45newslettersd8ErwXXv2lMeta } from "/mcc/src/pages/old-newsletters.vue?macro=true";
import { default as our_45coachesRtg6Om4DxeMeta } from "/mcc/src/pages/our-coaches.vue?macro=true";
import { default as our_45committeeJFbzjKuDWZMeta } from "/mcc/src/pages/our-committee.vue?macro=true";
import { default as cancelQDa2FnZZkdMeta } from "/mcc/src/pages/payments/cancel.vue?macro=true";
import { default as successn84mpTBscIMeta } from "/mcc/src/pages/payments/success.vue?macro=true";
import { default as _91_46_46_46slug_93sLaJ3CkZirMeta } from "/mcc/src/pages/profile/[...slug].vue?macro=true";
import { default as registerkYx1mXO6JDMeta } from "/mcc/src/pages/register.vue?macro=true";
import { default as reset_45passwordNKEiqiu0r9Meta } from "/mcc/src/pages/reset-password.vue?macro=true";
import { default as mailing_45listiHFkkwApYVMeta } from "/mcc/src/pages/unsubscribe/mailing-list.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/mcc/src/pages/[...slug].vue")
  },
  {
    name: "admin-emails",
    path: "/admin/emails",
    meta: emailsOK8grCxJlVMeta || {},
    component: () => import("/mcc/src/pages/admin/emails.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersJFAVv9uWKgMeta || {},
    component: () => import("/mcc/src/pages/admin/users.vue")
  },
  {
    name: "beginner-course-dates",
    path: "/beginner-course-dates",
    component: () => import("/mcc/src/pages/beginner-course-dates.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/mcc/src/pages/calendar.vue")
  },
  {
    name: "coached-paddles",
    path: "/coached-paddles",
    component: () => import("/mcc/src/pages/coached-paddles.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    component: () => import("/mcc/src/pages/confirm-email.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/mcc/src/pages/contact-us.vue")
  },
  {
    name: "demo-event",
    path: "/demo-event",
    component: () => import("/mcc/src/pages/demo-event.vue")
  },
  {
    name: "demo",
    path: "/demo",
    meta: demolk4jSFjkanMeta || {},
    component: () => import("/mcc/src/pages/demo.vue")
  },
  {
    name: "events-id-slug-edit",
    path: "/events/:id()/:slug(.*)*/edit",
    meta: editbYbI1UQG3EMeta || {},
    component: () => import("/mcc/src/pages/events/[id]/[...slug]/edit.vue")
  },
  {
    name: "events-id-slug",
    path: "/events/:id()/:slug(.*)*",
    component: () => import("/mcc/src/pages/events/[id]/[...slug]/index.vue")
  },
  {
    name: "events-consent",
    path: "/events/consent",
    meta: consentYePiv2EW1uMeta || {},
    component: () => import("/mcc/src/pages/events/consent.vue")
  },
  {
    name: "events-new",
    path: "/events/new",
    meta: newmzBmU05D4nMeta || {},
    component: () => import("/mcc/src/pages/events/new.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/mcc/src/pages/faqs.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/mcc/src/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index519BfSTGh2Meta || {},
    component: () => import("/mcc/src/pages/index.vue")
  },
  {
    name: "invites-accept",
    path: "/invites/accept",
    component: () => import("/mcc/src/pages/invites/accept.vue")
  },
  {
    name: "invites-create",
    path: "/invites/create",
    meta: createpOWqsBgavRMeta || {},
    component: () => import("/mcc/src/pages/invites/create.vue")
  },
  {
    name: "invites-send",
    path: "/invites/send",
    meta: sendruwuZscaUFMeta || {},
    component: () => import("/mcc/src/pages/invites/send.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginNWjMCkw1ecMeta || {},
    component: () => import("/mcc/src/pages/login.vue")
  },
  {
    name: "member-check",
    path: "/member-check",
    meta: member_45checkbpWLRV4KwFMeta || {},
    component: () => import("/mcc/src/pages/member-check.vue")
  },
  {
    name: "minutes",
    path: "/minutes",
    component: () => import("/mcc/src/pages/minutes/index.vue")
  },
  {
    name: "minutes-new",
    path: "/minutes/new",
    component: () => import("/mcc/src/pages/minutes/new.vue")
  },
  {
    name: "news-id-slug",
    path: "/news/:id()/:slug(.*)*",
    component: () => import("/mcc/src/pages/news/[id]/[...slug].vue")
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    component: () => import("/mcc/src/pages/news/[id]/edit.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/mcc/src/pages/news/index.vue")
  },
  {
    name: "news-new",
    path: "/news/new",
    meta: newnBRCl8JMgJMeta || {},
    component: () => import("/mcc/src/pages/news/new.vue")
  },
  {
    name: "news-unsubscribe",
    path: "/news/unsubscribe",
    component: () => import("/mcc/src/pages/news/unsubscribe.vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/mcc/src/pages/newsletters/index.vue")
  },
  {
    name: "newsletters-new",
    path: "/newsletters/new",
    component: () => import("/mcc/src/pages/newsletters/new.vue")
  },
  {
    name: "old-newsletters",
    path: "/old-newsletters",
    meta: old_45newslettersd8ErwXXv2lMeta || {},
    component: () => import("/mcc/src/pages/old-newsletters.vue")
  },
  {
    name: "our-coaches",
    path: "/our-coaches",
    component: () => import("/mcc/src/pages/our-coaches.vue")
  },
  {
    name: "our-committee",
    path: "/our-committee",
    component: () => import("/mcc/src/pages/our-committee.vue")
  },
  {
    name: "payments-cancel",
    path: "/payments/cancel",
    component: () => import("/mcc/src/pages/payments/cancel.vue")
  },
  {
    name: "payments-success",
    path: "/payments/success",
    component: () => import("/mcc/src/pages/payments/success.vue")
  },
  {
    name: "profile-slug",
    path: "/profile/:slug(.*)*",
    meta: _91_46_46_46slug_93sLaJ3CkZirMeta || {},
    component: () => import("/mcc/src/pages/profile/[...slug].vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerkYx1mXO6JDMeta || {},
    component: () => import("/mcc/src/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/mcc/src/pages/reset-password.vue")
  },
  {
    name: "unsubscribe-mailing-list",
    path: "/unsubscribe/mailing-list",
    meta: mailing_45listiHFkkwApYVMeta || {},
    component: () => import("/mcc/src/pages/unsubscribe/mailing-list.vue")
  }
]