import revive_payload_client_RjS3BmrIt4 from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jo9mWseuNY from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5GIb4JX7iP from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6LJSwM4vL8 from "/mcc/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@20.17.16_jiti@2._67snbcjf4z6r2uvr52g3i4ph5y/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_xgACuut96H from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jefpykWqQs from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h9iGlZqlAf from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rosrxAmINw from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_X2KIu3ZanU from "/mcc/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/mcc/.nuxt/components.plugin.mjs";
import prefetch_client_9OiaUvEWC6 from "/mcc/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__p2aw3k6l3fn2fph7tbwmze22mu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_smpyIkV1F2 from "/mcc/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_3ewkqhhrrpjeypxqih74ef6rru/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_TZHklGY4jR from "/mcc/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_3ewkqhhrrpjeypxqih74ef6rru/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Tl1QwEelDM from "/mcc/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_3ewkqhhrrpjeypxqih74ef6rru/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_tN72JH81xj from "/mcc/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_iImiZiRvBX from "/mcc/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@20.17.16_jiti@2.4.2_te_qdihjj6ajymqfz346jaqedclcy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/mcc/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/mcc/.nuxt/floating-vue.mjs";
import script_nVBWE1RHGe from "/mcc/node_modules/.pnpm/@nuxtjs+turnstile@0.6.3_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_kn3XtG8oDr from "/mcc/node_modules/.pnpm/nuxt-directus@5.7.0_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import umami_iN1DWXMIUx from "/mcc/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import authRefresh_client_zjlpzuMCqF from "/mcc/src/plugins/authRefresh.client.ts";
import redirects_oxa5gmVmKq from "/mcc/src/plugins/redirects.ts";
import sentry_client_KAXFuL2wum from "/mcc/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_RjS3BmrIt4,
  unhead_jo9mWseuNY,
  router_5GIb4JX7iP,
  _0_siteConfig_6LJSwM4vL8,
  payload_client_xgACuut96H,
  navigation_repaint_client_jefpykWqQs,
  check_outdated_build_client_h9iGlZqlAf,
  chunk_reload_client_rosrxAmINw,
  plugin_vue3_X2KIu3ZanU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9OiaUvEWC6,
  slideovers_smpyIkV1F2,
  modals_TZHklGY4jR,
  colors_Tl1QwEelDM,
  plugin_client_tN72JH81xj,
  plugin_iImiZiRvBX,
  plugin_wy0B721ODc,
  floating_vue_EIcJ7xiw0h,
  script_nVBWE1RHGe,
  plugin_kn3XtG8oDr,
  umami_iN1DWXMIUx,
  authRefresh_client_zjlpzuMCqF,
  redirects_oxa5gmVmKq,
  sentry_client_KAXFuL2wum
]